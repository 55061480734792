<template>
  <v-app>
    <v-container class="my-6 mx-auto" style="max-width: 1500px">
      <v-card>
        <v-card-text class="px-0 py-0">
          <StudentCalendar v-if="selectedClassGroup" :zClassGroup="selectedClassGroup"></StudentCalendar>
        </v-card-text>
      </v-card>
    </v-container>
  </v-app>
</template>
<script>
import { sync } from "vuex-pathify";
// const fb = require("@/firebaseConfig.js");
import StudentCalendar from "./StudentCalendar.vue"
export default {
  name: "light-table-transparent",
  computed: {
    ...sync("*"),
  },
  components:{
    StudentCalendar
  },
  data() {
    return {
      selectedClassGroup:null
    };
  },

  created() {
    console.log(this.userData)
    this.userData.school.ref.collection("departments-"+this.userData.school.currentYear).doc("department-"+ this.userData.ACADEMIC_LEVEL)
    .collection("programs").doc(this.userData.STUDENT_GROUP_ID.toString()).get().then((doc)=>{
      this.selectedClassGroup = doc.data()
      this.selectedClassGroup.id = doc.id
      this.selectedClassGroup.ref = doc.ref
      console.log(this.selectedClassGroup)
    })
  },
  mounted() {
    // this.userData.school.ref
    //   .collection("lessons")
    //   .where("lessonTypeName", "==", "Сонгон")
    //   .orderBy("name", "asc")
    //   .get()
    //   .then((querySnapshot) => {
    //     this.lessonCategories = [];
    //     querySnapshot.forEach((doc) => {
    //       let lesson = doc.data();
    //       lesson.id = doc.id;
    //       lesson.ref = doc.ref;
    //       lesson.name2 = lesson.name + " - " + lesson.lessonTypeName;
    //       this.lessonCategories.push(lesson);
    //     });
    //   });
  
      
  },
  methods: {
  
    
  },
};
</script>
