<template>
    <v-container  class="my-0 pa-0">
      <v-card class="pt-0">
        <div class="card-header-padding pt-2">
          <p class="py-2 text-center" style="background-color: yellow; color: red;">
            Онлайн хичээлд оролцох бол харгалцах хичээл дээрээ дарна уу! Эсвэл онлайн анги дээрээ дарна уу!
          </p>
          <v-row justify="space-between">
            <v-col>
              <div>
                <h5 class="font-weight-bold text-h5 text-typo mb-0" @click="_print">
                  <span class="blue--text text-uppercase">{{ _getMyClassInfo()["STUDENT_GROUP_NAME"] }}
                  </span>
                  - Ангийн xичээлийн xуваарь
                </h5>
                <p class="text-sm text-body mb-0">
                  Анги удирдсан багш ангийнxаа xичээлийн xуваарийг энд удирдана.
                </p>
              </div>
            </v-col>
            <v-col>
              <v-btn color="amber" elevation="0" @click="_goMeet()" v-if="zClassGroup.meetLink"> 
                  <v-icon class="mr-2">mdi-video</v-icon>Онлайн анги
              </v-btn>
              <p v-else class="red--text">Цахим анги үүсээгүй
                <v-icon class="" size="20" color="red" @click="$swal.fire('Ангийн багш ангийн цахим линк үүсгэнэ!')">
                mdi-information-variant-circle-outline
              </v-icon>
              </p>
            </v-col>
            <v-col md="2" lg="2" cols="2" class="pa-0 mr-2">
              <v-select style="background-color: #f8bbd0" :items="[1, 2, 3,4]" v-model="currentSelectedSemester"
                label="Улирал сонгоx">
              </v-select>
            </v-col>
          </v-row>
        </div>  
        <div v-if="lessons && lessons.length > 0">
          <div v-if="
            selectedClassGroup &&
            selectedClassGroup._classCalendarEelj_selected
          ">
            <div class="card-header-padding">
              <v-simple-table class="bborder" v-if="
                this.selectedClassGroup &&
                this.selectedClassGroup._classCalendarEeljs
              ">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th style="border-right: 1px solid #bbb; border-left: 1px solid #bbb;">
                        Нэр
                      </th>

                      <!-- <th style="width: 8%">Цаг</th> -->
                      <th style="width: 200px" v-for="(day, dindex) in days" :key="'calendar-' + day.dayIndex + dindex"
                        class="text-center font-weight-bold">
                        {{ day.name }}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="(xeelj, lindex) in xeeljs" :key="xeelj + lindex + 'eelj'">
                      <td style="border-right: 1px solid #bbb;
                                border-left: 1px solid #bbb;
                                min-width: 120px !important;
                              ">
                        {{ xeelj }} -р цаг
                      </td>
                      <td v-for="(day, dindex) in days"
                        :key="'cell-' + day.dayIndex + xeelj.index + dindex" :title="''" style="cursor: pointer"
                        class="px-1 mx-1 py-4">
                        <v-row>
                          <v-col cols="10">
                            <v-row>
                              <v-col class="pr-0 pb-1 pt-0" :cols="12 / _getCellLessons(day, xeelj).length" v-for="(lesson, index) in _getCellLessons(
                                day,
                                xeelj
                              )" :key="'cell' + day + xeelj + index">
                                <v-chip @click.stop="
                                  _clickedCell(day, xeelj, index, 1)
                                " class="px-2" style="
                                          width: 100%;
                                          height: 100%;
                                          font-size: 10pt;
                                          border-radius: 4px;
                                          cursor: pointer;
                                          display: block;
                                        " :style="_getLessonName(day, xeelj, index).style"
                                  v-if="_getLessonName(day, xeelj, index)" color="#00BCD4" dark>
                                  <p class="mb-0">
                                    {{ _getLessonName(day, xeelj, index).name }}
                                  </p>
                                  <small style="display: block">
                                    <span v-if="
                                      _getLessonName(day, xeelj, index)
                                        .teachers != null
                                    ">
                                      {{
                                        _getLessonName(day, xeelj, index).teachers
                                      }}
                                    </span>
                                    <span v-else>
                                      !!!!
                                      <v-icon>mdi-alert-decagram</v-icon></span>
                                  </small>
                                  <v-btn class="mb-2 red--text font-weight-bold" light color="white" 
                                  v-if=" _getLessonName(day, xeelj, index).isMine">Online</v-btn>
                                </v-chip>
                              </v-col>
                            </v-row>
                          </v-col>
                       
                        </v-row>
                        <!-- {{day.dayIndex}}-{{xeelj.index}} -->
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </div>
          <div v-if="
            selectedClassGroup &&
            selectedClassGroup._classCalendarEelj2_selected
          ">
            <div class="card-header-padding" v-if="selectedClassGroup._classCalendarEelj2">
              <v-row justify="space-between">
                <v-col>
                  <h3>
                    {{ selectedClassGroup._classCalendarEelj2.name2 }}
                  </h3>
                </v-col>
              </v-row>
            </div>

            <div class="card-header-padding">
              <v-simple-table class="bborder" v-if="this.calendarData">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th style="
                                border-right: 1px solid #bbb;
                                border-left: 1px solid #bbb;
                              ">
                        Нэр
                      </th>

                      <!-- <th style="width: 8%">Цаг</th> -->
                      <th style="width: 200px" v-for="(day, dindex) in days" :key="'calendar-' + day.dayIndex + dindex"
                        class="text-center font-weight-bold">
                        {{ day.name }}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="(xeelj, lindex) in xeeljs" :key="xeelj + lindex + 'eelj'">
                      <td style="
                                border-right: 1px solid #bbb;
                                border-left: 1px solid #bbb;
                                min-width: 120px !important;
                              ">
                        {{ xeelj }} -р цаг
                      </td>
                      <td v-for="(day, dindex) in days"
                        :key="'cell-' + day.dayIndex + xeelj.index + dindex" :title="''" style="cursor: pointer"
                        class="px-1 mx-1 py-4">
                        <v-row>
                          <v-col cols="10">
                            <v-row>
                              <v-col class="pr-0 pb-1 pt-0" :cols="
                                12 /
                                _getCellLessonsOfCalendarEelj2(day, xeelj)
                                  .length
                              " v-for="(
                                        lesson, index
                                      ) in _getCellLessonsOfCalendarEelj2(day, xeelj)"
                                :key="'cell' + day + xeelj + index">
                                <v-chip @click.stop="
                                  _clickedCell(day, xeelj, index, 2)
                                " class="px-2" style="
                                          width: 100%;
                                          height: 100%;
                                          font-size: 10pt;
                                          border-radius: 4px;
                                          cursor: pointer;
                                          display: block;
                                        " :style="
                                          _getLessonOfCalendarEelj2(day, xeelj, index)
                                            .style
                                        " v-if="_getLessonOfCalendarEelj2(day, xeelj, index)" color="#00BCD4" dark>
                                  <p class="mb-0">
                                    {{
                                      _getLessonOfCalendarEelj2(
                                        day,
                                        xeelj,
                                        index
                                      ).name
                                    }}
                                  </p>
                                  <small style="display: block">
                                    <span v-if="
                                      _getLessonOfCalendarEelj2(
                                        day,
                                        xeelj,
                                        index
                                      ).teachers != null
                                    ">
                                      {{
                                        _getLessonOfCalendarEelj2(
                                          day,
                                          xeelj,
                                          index
                                        ).teachers
                                      }}
                                    </span>
                                    <span v-else>
                                      !!!!
                                      <v-icon>mdi-alert-decagram</v-icon></span>
                                  </small>
                                </v-chip>
                              </v-col>
                            </v-row>
                          </v-col>
         
                        </v-row>
                        <!-- {{day.dayIndex}}-{{xeelj.index}} -->
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </div>
        </div>
      </v-card>
    </v-container>
</template>

<script>
// const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
export default {
  data() {
    return {
      currentSelectedSemester: 1,
      selectedCalendarIndex: 0,
      selectedCalendar: null,
      showEditCalendarDialog: false,
      school: null,
      selectedCalendarEelj: null,
      selectedEelj: null,
      selectedEelj2: null,
      showClassCalendarEeljDialog: false,
      showClassCalendarEelj2Dialog: false,
      calendarData: null,
      xeeljs: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
      calendarColors: [
        {
          subjectId: 1,
          fgColor: "#A5D6A7",
          bgColor: "black",
        },
        {
          subjectId: 2,
          fgColor: "#42A5F5",
          bgColor: "white",
        },
        {
          subjectId: 3,
          fgColor: "#90CAF9",
          bgColor: "black",
        },
        {
          subjectId: 4,
          fgColor: "#BBDEFB",
          bgColor: "black",
        },
        {
          subjectId: 5,
          fgColor: "#F48FB1",
          bgColor: "black",
        },
        {
          subjectId: 6,
          fgColor: "#C8E6C9",
          bgColor: "black",
        },
        {
          subjectId: 7,
          fgColor: "#A5D6A7",
          bgColor: "black",
        },
        {
          subjectId: 8,
          fgColor: "#81C784",
          bgColor: "black",
        },
        {
          subjectId: 9,
          fgColor: "#9575CD",
          bgColor: "white",
        },
        {
          subjectId: 10,
          fgColor: "#2962FF",
          bgColor: "white",
        },

        {
          subjectId: 11,
          fgColor: "#66BB6A",
          bgColor: "white",
        },

        {
          subjectId: 12,
          fgColor: "#FFF176",
          bgColor: "black",
        },
        {
          subjectId: 13,
          fgColor: "#FFD54F",
          bgColor: "black",
        },
        {
          subjectId: 14,
          fgColor: "#FFCA28",
          bgColor: "black",
        },
        {
          subjectId: 15,
          fgColor: "#FFAB91",
          bgColor: "black",
        },
        {
          subjectId: 16,
          fgColor: "#FF8A65",
          bgColor: "black",
        },
        {
          subjectId: 17,
          fgColor: "#FF7043",
          bgColor: "white",
        },
        {
          subjectId: 18,
          fgColor: "#B0BEC5",
          bgColor: "black",
        },
        {
          subjectId: 19,
          fgColor: "#F4511E",
          bgColor: "white",
        },
        {
          subjectId: 20,
          fgColor: "#E040FB",
          bgColor: "white",
        },
        {
          subjectId: 21,
          fgColor: "#40C4FF",
          bgColor: "black",
        },
        {
          subjectId: 22,
          fgColor: "#00E676",
          bgColor: "black",
        },
        {
          subjectId: 23,
          fgColor: "#DCE775",
          bgColor: "black",
        },
        {
          subjectId: 24,
          fgColor: "#FF5252",
          bgColor: "white",
        },
        {
          subjectId: 25,
          fgColor: "#FFE082",
          bgColor: "black",
        },
        {
          subjectId: 26,
          fgColor: "#B2DFDB",
          bgColor: "black",
        },
        {
          subjectId: 27,
          fgColor: "#CFD8DC",
          bgColor: "black",
        },
        {
          subjectId: 28,
          fgColor: "#CFD8DC",
          bgColor: "black",
        },

        {
          subjectId: 195,
          fgColor: "#FFE082",
          bgColor: "black",
        },

        {
          subjectId: 196,
          fgColor: "#26C6DA",
          bgColor: "white",
        },
      ],
      selectedClassGroup: null,
      lessons: null,
      selectedLesson: null,
      selectLessonDialog: false,
      deleteConfirmDialog: false,
      days: [
        { name: "Даваа", dayIndex: 1 },
        { name: "Мягмар", dayIndex: 2 },
        { name: "Лхагва", dayIndex: 3 },
        { name: "Пүрэв", dayIndex: 4 },
        { name: "Баасан", dayIndex: 5 },
        // { name: "Бямба", dayIndex: 6 },
        // { name: "Ням", dayIndex: 7 },
      ],
      availableTimes: null,
      selectedXeelj: null,
      selectedDay: null,
      xeelj: null,
    };
  },
  props: {
    zClassGroup: {
      type: Object,
    },
  },
  methods: {
    _goMeet(){
      window.open(this.zClassGroup.meetLink, '_blank');
    },
    _getFilteredXeelj() {
      var list = [];
      if (
        this.selectedClassGroup &&
        this.selectedClassGroup._classCalendarEeljs &&
        this.xeelj
      ) {
        for (const eelj of this.xeelj) {
          if (
            eelj.ref.path !=
            this.selectedClassGroup._classCalendarEeljs.ref.path
          ) {
            list.push(eelj);
          }
        }
      }
      return list;
    },
    _backPop() {
      this.$router.back();
      this.school.ref.collection("_feedback_calendars").doc().set({
        school: this.school,
        problem: "eelj baih bna.",
      });
    },
    _print() {
      console.log(this.selectedClassGroup.ref.path);
      if (this.selectedLesson) console.log(this.selectedLesson.ref.path);
    },
    _getEelj() {
      var names = null;
      if (this.selectedClassGroup._classCalendarEeljs) {
        names = this.selectedClassGroup._classCalendarEeljs.name2;
      }
      return names;
    },
    _getCellColor(lesson) {
      var x = this.calendarColors.find(
        (item) => item.subjectId == lesson.courseInfo.SUBJECT_AREA_ID
      );
      if (x) return x;
      else {
        return {
          subjectId: -1,
          fgColor: "#bbb",
          bgColor: "black",
        };
      }
    },
    _getTeacherNames(item) {
      var names = null;
      if (item.byTeachers) {
        item.byTeachers.filter(
            (tt) => !tt.xSemester || (tt.xSemester && tt.xSemester == this.currentSelectedSemester)
          ).forEach((teacher) => {
          names =
            names == null
              ? teacher.teacherDisplayName
              : names + ", " + teacher.teacherDisplayName;
        });
      }

      return names;
    },
    _getMyClassInfo() {
      return this.zClassGroup
    },
    async _clickedCell(day, xeelj, index) {
      // console.log(day, xeelj, index, calendarEeljIndex)
      if(day.dayIndex>=1){
        var ca = this._getLessonName(day, xeelj, index)
        if(ca.isMine&&ca.lessonGroup){
          if(ca.lessonGroup.teacher){
            ca.lessonGroup.teacher.teacherRef.get().then(doc=>{
              if(doc.exists&&doc.data().meetLink){
                window.open(doc.data().meetLink, '_blank');
              }else{
                this.$swal.fire("Групп, сонгон хичээлийн багш линк үүсгээгүй байна!")
              }
            })
          }
        }
        else if(ca.lessonRef){
          ca.lessonRef.get().then(doc=>{
            let lesson = doc.data()
              if(lesson.esisLessonType&&lesson.esisLessonType.esisLessonTypeId==1){
                this._goMeet()
              }else{
                console.log("akhkah",ca)
              }
          })
        }
      }else{
        this.$swal.fire("Цахимаар хичээллэх өдөр биш байна!")
      }
      // this.selectedDay = day;
      // this.selectedXeelj = xeelj;
      // if (calendarEeljIndex == 1) {
      //   this.selectedLesson = this._getCellLessons(day, xeelj)[index];
      // } else {
      //   this.selectedLesson = this._getCellLessonsOfCalendarEelj2(day, xeelj)[
      //     index
      //   ];
      // }
      // this.selectedLesson.already = true;
      // this.selectLessonDialog = true;

    },
    _getLesson(day, xeelj) {
      var kk = null;
      if (this.selectedClassGroup && this.calendarData) {
        for (var ca of this.calendarData) {
          if (ca.dayIndex == day.dayIndex && ca.xeelj == xeelj) {
            kk = ca;
            var cc = this._getCellColor(ca);
            kk.style =
              "background-color:" + cc.fgColor + "; color:" + cc.bgColor + ";";
            kk.name = kk.courseInfo.SUBJECT_AREA_NAME.substring(0, 15);
            kk.teachers = this._getTeacherNames(kk);

            break;
          }
        }
      }
      return kk;
      // return "BB-" + day.dayIndex + "/" + xeelj.index;
    },
    _getLessonName(day, xeelj, index) {
      var ll = this._getCellLessons(day, xeelj);
      return ll[index];
    },
    _getCellLessons(day, xeelj) {
      var lessons = [];
      if (this.selectedClassGroup && this.calendarData) {
        for (var ca of this.calendarData) {
          if (
            ca.calendarEelj &&
            ca.calendarEelj.eeljRef.path ==
            this.selectedClassGroup._classCalendarEeljs.ref.path
          ) {
            if (ca.dayIndex == day.dayIndex && ca.xeelj == xeelj) {
              var kk = ca;
              var cc = this._getCellColor(ca);
              kk.style =
                "background-color:" +
                cc.fgColor +
                "; color:" +
                cc.bgColor +
                ";";
              kk.name = kk.courseInfo.SUBJECT_AREA_NAME.substring(0, 15);
              if (kk.lessonGroup) {
                kk.name = "[" + kk.lessonGroup.groupIndex + "] " + kk.name;
                kk.teachers = kk.lessonGroup.teacher
                  ? kk.lessonGroup.teacher.teacherDisplayName
                  : null;
              } else kk.teachers = this._getTeacherNames(kk);
              if(kk.lessonGroup){
                if(kk.isMine) lessons.push(kk);
              }
              else lessons.push(kk);
            }
          }
        }
      }
      return lessons;
    },
    _getLessonOfCalendarEelj2(day, xeelj, index) {
      var ll = this._getCellLessonsOfCalendarEelj2(day, xeelj);
      return ll[index];
    },
    _getCellLessonsOfCalendarEelj2(day, xeelj) {
      var lessons = [];
      if (this.selectedClassGroup && this.calendarData) {
        for (var ca of this.calendarData) {
          if (
            ca.calendarEelj &&
            ca.calendarEelj.eeljRef.path ==
            this.selectedClassGroup._classCalendarEelj2.ref.path
          ) {
            if (ca.dayIndex == day.dayIndex && ca.xeelj == xeelj) {
              var kk = ca;
              var cc = this._getCellColor(ca);
              kk.style =
                "background-color:" +
                cc.fgColor +
                "; color:" +
                cc.bgColor +
                ";";
              kk.name = kk.courseInfo.SUBJECT_AREA_NAME.substring(0, 15);
              if (kk.lessonGroup) {
                kk.name = "[" + kk.lessonGroup.groupIndex + "] " + kk.name;
                kk.teachers = kk.lessonGroup.teacher
                  ? kk.lessonGroup.teacher.teacherDisplayName
                  : null;
              } else kk.teachers = this._getTeacherNames(kk);
              lessons.push(kk);
            }
          }
        }
      }
      return lessons;
      // return "BB-" + day.dayIndex + "/" + xeelj.index;
    },
    async _setupp() {
      if (this.school == null) this.school = this.userData.school;
      this.selectedClassGroup = this.zClassGroup
      this.currentSelectedSemester = 3
            this.school.ref
              .collection("xeelj")
              .get()
              .then((docs) => {
                this.xeelj = [];
                docs.docs.forEach((doc) => {
                  let eelj = doc.data();
                  eelj.id = doc.id;
                  eelj.ref = doc.ref;
                  // console.log(eelj.classLevelID);
                  if (parseInt(this.selectedClassGroup.ACADEMIC_LEVEL) > 5) {
                    if (eelj.classLevelID == 2) {
                      eelj.name2 = eelj.name + ", " + eelj.xeelj + "-р ээлж";
                      this.xeelj.push(eelj);
                    }
                  } else {
                    if (eelj.classLevelID == 1) {
                      eelj.name2 = eelj.name + ", " + eelj.xeelj + "-р ээлж";
                      // console.log(eelj.id);
                      this.xeelj.push(eelj);
                    }
                  }
                });
              });
            this.school.ref
              .collection("lessons-" + this.school.currentYear)
              .where("programId", "==", this.selectedClassGroup.id)
              .orderBy("courseInfo.COURSE_NAME", "asc")
              .onSnapshot((docs) => {
                this.lessons = [];
                docs.forEach((doc) => {
                  let lesson = doc.data();
                  lesson.ref = doc.ref;
                  lesson.id = doc.id;
                  lesson.name2 = lesson.courseInfo.COURSE_NAME
                    ? this.lessons.length +
                    1 +
                    ". " +
                    lesson.courseInfo.COURSE_NAME
                    : "";

                  if (
                    lesson.esisLessonType &&
                    lesson.esisLessonType.esisLessonTypeId > 1
                  ) {
                    lesson.lessonGroups = null;
                    lesson.ref
                      .collection("lesson-groups")
                      .get()
                      .then((docs) => {
                        lesson.lessonGroups = [];
                        docs.docs.forEach((doc) => {
                          let gg = doc.data();
                          gg.id = doc.id;
                          gg.ref = doc.ref;
                          lesson.lessonGroups.push(gg);
                        });
                      });
                  }
                  this.lessons.push(lesson);
                });
              });
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
  },
  created() {
    this._setupp();
  },
  watch: {
    currentSelectedSemester(val) {
      console.log(val, this.currentSelectedSemester)
      this.selectedClassGroup.ref
        .collection("calendar-" + this.school.currentYear + "-" + this.currentSelectedSemester)
        .orderBy("courseInfo.COURSE_NAME", "asc")
        .onSnapshot((docs) => {
          this.calendarData = [];
          docs.forEach(async (doc) => {
            let cal = doc.data();
            cal.ref = doc.ref;
            cal.id = doc.id;
       
            if (cal.lessonGroup) {
              cal.lessonGroup.ref.get().then(async (g) => {
                if (g.exists == false) cal.ref.delete();
                else {
                  let calG = g.data();
                  calG.id = g.id;
                  calG.ref = g.ref;
                  cal.lessonGroup = calG;
                  var xx = this.calendarData.find(item => item.id == cal.id)
                  if (!xx){
                   if( cal.lessonGroup.allStudentsSelected){
                    cal.isMine = true
                    this.calendarData.push(cal);
                   }else{
                    var yy = await cal.lessonGroup.ref.collection("students").doc(this.userData.id).get();
                    if(yy.exists){
                       cal.isMine = true
                    } 
                    this.calendarData.push(cal);
                   }
                  
                  } 
                }
              });
            } else {
             
              cal.lessonGroup = null;
              var zzz = await cal.lessonRef.get();
              if (zzz.exists) {
                cal.byTeachers = zzz.data().byTeachers
                  ? zzz.data().byTeachers
                  : null;
              } else cal.byTeachers = [];
              var xx = this.calendarData.find(item => item.id == cal.id)
              if (!xx)
                this.calendarData.push(cal);
            }
          });
        });
    }
  }
};
</script>

<style>
.bborder td {
  border-right: 1px solid #bbb !important;
}

.bborder th {
  border-right: 1px solid #bbb;
  border-top: 1px solid #bbb;
}

.bborder .borderCell:hover {
  background-color: red;
}

.v-chip .v-chip__content {
  align-items: start !important;
  display: block;
  height: 100%;
  max-width: 100%;
}

.addIcon:hover {
  font-size: 34px;
  transition: font-size 0.2s;
  /* Add a smooth transition for a nicer effect */
}

[data-title2]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title2]:after {
  content: attr(data-title2);
  position: absolute;
  bottom: -1.6em;
  left: 65%;
  padding: 4px 4px 4px 8px;
  color: #222;
  white-space: nowrap;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0px 0px 4px #222;
  -webkit-box-shadow: 0px 0px 4px #222;
  box-shadow: 0px 0px 4px #222;
  background: #2196f3;
  color: white;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
}

[data-title2] {
  position: relative;
}
</style>
